import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql } from 'gatsby';

import useLocale from '../../hooks/useLocale';

import Layout from '../../components/Layout';
import Link from '/src/components/LocalizedLink';
import ForthcomingCourses from '../../components/ForthcomingCourses';
import AplicationProcess from '../../components/AplicationProcess';
import ReviewsSection from '../../components/ReviewsSection';
import Faq from '../../components/Faq';

import IconArrowRight from '../../assets/images/svg/arrow-right.inline.svg';
import IconArrowRightRed from '../../assets/images/svg/arrow-right-red.inline.svg';
import iconCalendar from '../../assets/images/svg/calendar.svg';
import iconClock from '../../assets/images/svg/clock.svg';
import iconTick from '../../assets/images/svg/tick.svg';
import iconColumn from '../../assets/images/trinity-cert-tesol-course-page/column.svg';
import imgBenefit1 from '../../assets/images/developing-teacher-course/Networking And Peer Support.svg';
import imgBenefit2 from '../../assets/images/developing-teacher-course/Qualify For A Student Visa.svg';
import imgBenefit3 from '../../assets/images/developing-teacher-course/Study At Your Own Pace.svg';
import imgBenefit5 from '../../assets/images/developing-teacher-course/Small Group Sizes 2.svg';
import imgReview from '../../assets/images/spanish-courses/Spanish course - Clarisbelle Reyftmann.png';
import iconQuotes from '../../assets/images/trinity-cert-tesol-course-page/quotes.svg';
import imgDots from '../../assets/images/trinity-cert-tesol-course-page/Dots.svg';
import imgTrinityCertCourse from '../../assets/images/other-popular-courses/Trinity CertTESOL Course (1).png';
import imgTeachYoungLearners from '../../assets/images/other-popular-courses/Teacher Young Learners (1).png';
import imgIntensiveSpanish from '../../assets/images/other-popular-courses/Intensive Spanish Course (1).png';
import iconAccomodation from '../../assets/images/student-support/Accommodation (1).svg';
import iconStudentVisa from '../../assets/images/student-support/Student visas (1).svg';
import iconCulture from '../../assets/images/student-support/Culture.svg';
import iconBureaucracy from '../../assets/images/student-support/Bureaucracy.svg';

import fileDevelopingTeacherSyllabus from '../../assets/files/Developing Teacher syllabus.pdf';
import fileTEFLIberiaBrochure from '../../assets/files/TEFL Iberia brochure web.pdf';

import '../../assets/styles/pages/developing-teacher-course.scss';

const faq1 = `Will I be able to process a student visa with this course?`;
const faq1Answer = `Yes, the course satisfies the criteria for a long-term student visa.`;
const faq2 = `Can I work on a student visa?`;
const faq2Answer = `If you apply for a long-term student visa (more than 6 months), yes, you can work through a “convenio de prácticas”, which is like an agreement between us (your school) and your employer that you will be paid work experience. We will provide you with all the necessary paperwork and give instructions to your employer on how to arrange it.`;
const faq3 = `Will I still have time to work?`;
const faq3Answer = `Yes, the input sessions are at 9am in the morning, meaning you have all day and evening for working, which is when most English classes take place. The course is designed so you can conveniently manage your own time and the workload.`;
const faq4 = `Can I do this course without having done a TEFL course?`;
const faq4Answer = `No, this is a post-TEFL training course and assumes a certain amount of knowledge. You would need at least 120 hours of TEFL training (online or face-to-face) in order to be able to take part in the course.`;
const faq5 = `I would like to process a 12 month student visa, can I do that with this course?`;
const faq5Answer = `Yes, you can either study the Developing Teacher course for 12 months or combine Spanish lessons and the Developing Teacher course to qualify for a 12-month student visa.`;
const faq6 = `What is the average cost of living in Spain?`;

const esFaq1 = `¿Podré obtener una visa de estudiante con este curso?`;
const esFaq1Answer = `Sí, el curso cumple con los criterios para obtener una visa de estudiante de larga duración.`;
const esFaq2 = `¿Puedo trabajar con una visa de estudiante?`;
const esFaq2Answer = `Si solicitas una visa de estudiante de larga duración (más de 6 meses), sí, puedes trabajar a través de un "convenio de prácticas", que es un acuerdo entre nosotros (tu escuela) y tu empleador en el que se acuerda que tendrás experiencia laboral remunerada. Te proporcionaremos toda la documentación necesaria y daremos instrucciones a tu empleador sobre cómo organizarlo.`;
const esFaq3 = `¿Tendré tiempo para trabajar?`;
const esFaq3Answer = `Sí, las sesiones teóricas son a las 09.00 de la mañana, lo que significa que tendrás todo el día y la noche para trabajar, que es cuando la mayoría de las clases de inglés se llevan a cabo. El curso está diseñado para que puedas administrar tu propio tiempo y la carga de trabajo de manera conveniente.`;
const esFaq4 = `¿Puedo hacer este curso sin haber hecho un curso TEFL?`;
const esFaq4Answer = `No, este es un curso de formación posterior a la certificación TEFL y asume cierto nivel de conocimiento. Necesitarías al menos 120 horas de formación TEFL (en línea o presencial) para poder participar en el curso.`;
const esFaq5 = `Me gustaría obtener una visa de estudiante de 12 meses, ¿puedo hacerlo con este curso?`;
const esFaq5Answer = `Sí, puedes estudiar el curso de Desarrollo de Profesor durante 12 meses o combinar lecciones de español y el curso de Desarrollo de Profesor para calificar para una visa de estudiante de 12 meses.
`;

const DevelopingTeacherCourse = ({ data }) => {
  const allPrismicCourses = data.allPrismicCourses.edges;
  const [isMore, setIsMore] = useState(false);

  const { enLocale } = useLocale({ matchPathName: '/es/' });
  return (
    <Layout
      title={{ id: 'developingTeacher.seo.title' }}
      description={{ id: 'developingTeacher.seo.description' }}
    >
      <div className="developing-teacher-course">
        <section className="c-first-section c-first-section--sm">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-9 col-xl-7">
                <div className="c-first-section__schedule">
                  <div className="item__wrapper">
                    <img src={iconCalendar} alt="calendar" />
                    <span className="c-text-14">
                      <FormattedMessage id="developingTeacher.sec1.weeks" />
                    </span>
                  </div>
                  <div className="item__wrapper">
                    <img src={iconClock} alt="clock" />
                    <span className="c-text-14">
                      <FormattedMessage id="developingTeacher.sec1.hours" />
                    </span>
                  </div>
                  <div className="item__wrapper">
                    <img src={iconColumn} alt="cities" />
                    <span className="c-text-14">
                      <FormattedMessage id="developingTeacher.sec1.cities" />
                    </span>
                  </div>
                </div>
                <h1 className="c-first-section__title">
                  <FormattedMessage id="developingTeacher.sec1.title" />
                </h1>
                <p className="c-first-section__description">
                  <FormattedMessage id="developingTeacher.sec1.description" />
                </p>
                <div className="c-first-section__btns">
                  <FormattedMessage id="url.apply.index">
                    {(locale) => (
                      <Link to={locale[0]} className="c-btn c-btn--red">
                        <FormattedMessage id="menu.applyNow" />
                        <IconArrowRight />
                      </Link>
                    )}
                  </FormattedMessage>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="second-section c-section-py">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h2 className="c-title-34 second-section__l">
                  <FormattedMessage id="developingTeacher.sec2.title" />
                </h2>
                <p className="c-text-18 description second-section__l">
                  <FormattedMessage id="developingTeacher.sec2.description" />
                </p>
                <div className="c-benefit-list second-section__l">
                  <h4 className="c-text-16">
                    <FormattedMessage id="developingTeacher.sec2.benefitsTitle" />
                  </h4>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">
                      <FormattedMessage id="developingTeacher.sec2.benefit1" />
                    </p>
                  </div>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">
                      <FormattedMessage id="developingTeacher.sec2.benefit2" />
                    </p>
                  </div>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">
                      <FormattedMessage id="developingTeacher.sec2.benefit3" />
                    </p>
                  </div>
                </div>

                <div className="key-info">
                  <h4 className="c-text-13">
                    {' '}
                    <FormattedMessage id="developingTeacher.sec2.key" />
                  </h4>
                  <div className="key-info__wrapper">
                    <div className="item">
                      <h3 className="c-text-16">
                        <FormattedMessage id="developingTeacher.sec2.key1.title" />
                      </h3>
                      <p className="c-text-14">
                        <FormattedMessage id="developingTeacher.sec2.key1.text" />
                      </p>
                    </div>
                    <div className="item">
                      <h3 className="c-text-16">
                        <FormattedMessage id="developingTeacher.sec2.key2.title" />
                      </h3>
                      <p className="c-text-14">
                        <FormattedMessage id="developingTeacher.sec2.key2.text" />
                      </p>
                    </div>
                    <div className="item">
                      <h3 className="c-text-16">
                        <FormattedMessage id="developingTeacher.sec2.key3.title" />
                      </h3>
                      <p className="c-text-14">
                        <FormattedMessage id="developingTeacher.sec2.key3.text" />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="wrapper">
                  <div className="benefit-group">
                    <div className="group__img">
                      <img src={imgBenefit1} alt="Benefit1" />
                    </div>
                    <div className="group__text">
                      <h3 className="c-title-18">
                        <FormattedMessage id="developingTeacher.sec2.ben1.title" />
                      </h3>
                      <p className="c-text-14">
                        <FormattedMessage id="developingTeacher.sec2.ben1.text" />
                      </p>
                    </div>
                  </div>
                  <div className="benefit-group">
                    <div className="group__img">
                      <img src={imgBenefit2} alt="Benefit2" />
                    </div>
                    <div className="group__text">
                      <h3 className="c-title-18">
                        <FormattedMessage id="developingTeacher.sec2.ben2.title" />
                      </h3>
                      <p className="c-text-14">
                        <FormattedMessage id="developingTeacher.sec2.ben3.text" />
                      </p>
                    </div>
                  </div>
                  <div className="benefit-group">
                    <div className="group__img">
                      <img src={imgBenefit3} alt="Benefit3" />
                    </div>
                    <div className="group__text">
                      <h3 className="c-title-18">
                        <FormattedMessage id="developingTeacher.sec2.ben3.title" />
                      </h3>
                      <p className="c-text-14">
                        <FormattedMessage id="developingTeacher.sec2.ben3.text" />
                      </p>
                    </div>
                  </div>
                  <div className="benefit-group">
                    <div className="group__img">
                      <img src={imgBenefit5} alt="Benefit4" />
                    </div>
                    <div className="group__text">
                      <h3 className="c-title-18">
                        <FormattedMessage id="developingTeacher.sec2.ben4.title" />
                      </h3>
                      <p className="c-text-14">
                        <FormattedMessage id="developingTeacher.sec2.ben4.text" />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="third-section c-section-includes c-section-py">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="block_l">
                  <div className="block_l__img">
                    <img src={imgReview} alt="Clarisbelle Reyftmann" className="img-main" />
                    <img src={imgDots} alt="dots" className="dots" />
                  </div>
                  <div className="block_l__text">
                    <img src={iconQuotes} alt="" />
                    <p>
                      <FormattedMessage id="developingTeacher.sec3.quotation" />
                      <br />
                      <strong>Clarisbelle Reyftmann</strong>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="block_r">
                  <h2 className="c-title-34">
                    <FormattedMessage id="developingTeacher.sec3.title" />
                  </h2>
                  <div className="c-benefit-list block_r__benefits">
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        <FormattedMessage id="developingTeacher.sec3.benefit1" />
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        {' '}
                        <FormattedMessage id="developingTeacher.sec3.benefit2" />
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        <FormattedMessage id="developingTeacher.sec3.benefit3" />
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        <FormattedMessage id="developingTeacher.sec3.benefit4" />
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        <FormattedMessage id="developingTeacher.sec3.benefit5" />
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        <FormattedMessage id="developingTeacher.sec3.benefit6" />
                      </p>
                    </div>
                  </div>
                  <div className="block_r__price block_r__price--full">
                    <h3 className="c-text-13">
                      <FormattedMessage id="developingTeacher.sec3.discountPrice" />{' '}
                      <span className="notion">
                        <FormattedMessage id="developingTeacher.sec3.payInFull" />
                      </span>
                    </h3>
                    <h3 className="c-title-32">
                      1500€{' '}
                      <span className="lowercase">
                        /<FormattedMessage id="developingTeacher.sec3.oneYear" />
                      </span>
                    </h3>
                  </div>
                  <div className="block_r__price block_r__price--early-bird">
                    <h3 className="c-text-13">
                      <FormattedMessage id="developingTeacher.sec3.fullPrice" />
                    </h3>
                    <h3 className="c-title-32">
                      150€{' '}
                      <span className="lowercase">
                        /<FormattedMessage id="developingTeacher.sec11.month" />
                      </span>
                    </h3>
                  </div>
                  <div className="block_r__btns">
                    {/* <Link to="/apply/" className="c-btn c-btn--red">
                      Apply Now
                      <IconArrowRight />
                    </Link>
                    <a
                      href={fileTEFLIberiaBrochure}
                      className="c-btn c-btn--red-border"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Download Brochure
                      <IconArrowRightRed />
                    </a> */}

                    <FormattedMessage id="url.apply.index">
                      {(locale) => (
                        <Link to={locale[0]} className="c-btn c-btn--red">
                          <FormattedMessage id="menu.applyNow" />
                          <IconArrowRight />
                        </Link>
                      )}
                    </FormattedMessage>
                    <FormattedMessage id="url.tefl-iberia-brochure.index">
                      {(locale) => (
                        <Link to={locale[0]} className="c-btn c-btn--red-border">
                          <FormattedMessage id="homepage.sec6.downloadBrochure" />
                          <IconArrowRightRed />
                        </Link>
                      )}
                    </FormattedMessage>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="fourth-section">
          <div className="container">
            <ForthcomingCourses
              cardData={allPrismicCourses.slice(0, isMore ? allPrismicCourses.length : 4)}
              title={{ id: 'forthcomingCourses.title.upcoming' }}
            />
            {allPrismicCourses.length > 4 ? (
              <button
                onClick={() => setIsMore(!isMore)}
                className="c-btn c-btn--red-border load-more-dates"
              >
                {isMore ? (
                  'Show Less Dates'
                ) : (
                  <FormattedMessage id="forthcomingCourses.active.loadMore" />
                )}
                <IconArrowRightRed />
              </button>
            ) : null}
          </div>
        </section>

        <section className="fifth-section c-student-support-section">
          <div className="container">
            <h2 className="c-title-34">
              <FormattedMessage id="studentSupport.title" />
            </h2>
            <p className="c-text-18">
              <FormattedMessage id="studentSupport.description" />
            </p>
            <div className="row">
              <div className="col-lg-3 col-sm-6">
                <div className="item">
                  <img src={iconAccomodation} alt="" />
                  <h3 className="c-title-22">
                    <FormattedMessage id="studentSupport.accommodation" />
                  </h3>
                  <FormattedMessage id="url.accommodation">
                    {(locale) => (
                      <Link to={locale[0]} className="c-btn c-btn--white">
                        <FormattedMessage id="homepage.sec7.i.findOutMore" />
                        <IconArrowRightRed />
                      </Link>
                    )}
                  </FormattedMessage>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="item">
                  <img src={iconStudentVisa} alt="" />
                  <h3 className="c-title-22">
                    <FormattedMessage id="studentSupport.studentVisa" />
                  </h3>
                  <FormattedMessage id="url.working-as-a-teacher.student-visas">
                    {(locale) => (
                      <Link to={locale[0]} className="c-btn c-btn--white">
                        <FormattedMessage id="homepage.sec7.i.findOutMore" />
                        <IconArrowRightRed />
                      </Link>
                    )}
                  </FormattedMessage>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="item">
                  <img src={iconCulture} alt="" />
                  <h3 className="c-title-22">
                    <FormattedMessage id="studentSupport.culture" />
                  </h3>
                  <FormattedMessage id="url.guide-to-living-and-teaching-english-in-barcelona">
                    {(locale) => (
                      <Link to={locale[0]} className="c-btn c-btn--white">
                        <FormattedMessage id="homepage.sec7.i.findOutMore" />
                        <IconArrowRightRed />
                      </Link>
                    )}
                  </FormattedMessage>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <img src={imgDots} className="dots" alt="" />
                <div className="item">
                  <img src={iconBureaucracy} alt="" />
                  <h3 className="c-title-22">
                    <FormattedMessage id="studentSupport.bureaucracy" />
                  </h3>
                  <FormattedMessage id="url.working-as-a-teacher.student-visas">
                    {(locale) => (
                      <Link to={locale[0]} className="c-btn c-btn--white">
                        <FormattedMessage id="homepage.sec7.i.findOutMore" />
                        <IconArrowRightRed />
                      </Link>
                    )}
                  </FormattedMessage>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sixth-section">
          <AplicationProcess />
        </section>

        <section className="seventh-section">
          <div className="container">
            <h2 className="c-title-38">
              <FormattedMessage
                id="trinityCerttesol.sec7.title"
                values={{
                  accent: (chunk) => <span className="colored no-link">{chunk}</span>,
                }}
              />
            </h2>

            <FormattedMessage id="url.apply.index">
              {(locale) => (
                <Link to={locale[0]} className="c-btn c-btn--red">
                  <FormattedMessage id="sform.signUpNow" />
                  <IconArrowRight />
                </Link>
              )}
            </FormattedMessage>
          </div>
        </section>

        <section className="eigth-section c-faqs-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-12">
                <h3 className="c-title-34">
                  <FormattedMessage id="trinityCerttesol.sec8.title" />
                </h3>
                <p className="c-text-18">
                  <FormattedMessage
                    id="developingTeacher.sec8.description"
                    values={{
                      accent: (chunk) => <span className="text-fw-600">{chunk}</span>,
                    }}
                  />
                </p>
                <a
                  target="_blank"
                  href={fileDevelopingTeacherSyllabus}
                  className="c-btn c-btn--red"
                >
                  <FormattedMessage id="developingTeacher.btn.downloadTrinitySyllabus" />
                </a>
              </div>
              <div className="col-lg-6 col-12">
                <div className="assesment-block">
                  <h4 className="c-title-20">
                    <FormattedMessage id="trinityCerttesol.sec8.notification.title" />
                  </h4>
                  <p className="c-text-16">
                    <FormattedMessage id="trinityCerttesol.sec8.notification.description" />
                  </p>
                  <a
                    href="https://www.tryinteract.com/share/quiz/5fa530f1849de80014ec5fbc"
                    className="c-btn c-btn--red-border"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage id="btn.takeEnglisAssessment" />
                    <IconArrowRightRed />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="nineth-section">
          <ReviewsSection
            hideAllReviews
            description={{ id: 'graduates.teflSpanishCourses.description' }}
            title={{ id: 'graduates.teflSpanishCourses.title' }}
          />
        </section>

        <section className="tenth-section c-faqs-section">
          <div className="container">
            <div className="c-faqs-section__title">
              <h2 className="c-title-34">
                <FormattedMessage id="common.faqs" />
              </h2>
              <FormattedMessage id="url.courses.faq">
                {(locale) => (
                  <Link to={locale[0]} className="c-btn c-btn--white">
                    <FormattedMessage id="btn.viewMoreFaqs" />
                    <IconArrowRightRed />
                  </Link>
                )}
              </FormattedMessage>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <Faq
                  title={enLocale ? faq1 : esFaq1}
                  description={enLocale ? faq1Answer : esFaq1Answer}
                />
                <Faq
                  title={enLocale ? faq2 : esFaq2}
                  description={enLocale ? faq2Answer : esFaq2Answer}
                />
                <Faq
                  title={enLocale ? faq3 : esFaq3}
                  description={enLocale ? faq3Answer : esFaq3Answer}
                />
              </div>
              <div className="col-lg-6">
                <Faq
                  title={enLocale ? faq4 : esFaq4}
                  description={enLocale ? faq4Answer : esFaq4Answer}
                />
                <Faq
                  title={enLocale ? faq5 : esFaq5}
                  description={enLocale ? faq5Answer : esFaq5Answer}
                />
              </div>
            </div>
          </div>
        </section>

        <section className="eleventh-section c-other-courses-section">
          <div className="container">
            <h2 className="c-title-34">
              <FormattedMessage
                id="trinityCerttesol.sec11.title"
                values={{
                  accent: (chunk) => <span className="colored no-link">{chunk}</span>,
                }}
              />
            </h2>
            <p className="c-text-18">
              <FormattedMessage id="trinityCerttesol.sec11.description" />
            </p>
            <div className="row">
              <div className="col-sm-6 col-lg-4">
                <FormattedMessage id="url.courses.one-month-160-hour-tefl-course.index">
                  {(locale) => (
                    <Link to={locale[0]} className="item">
                      <img
                        src={imgTrinityCertCourse}
                        alt="imgTrinityCertCourse"
                        className="item__img"
                      />
                      <p className="c-text-14">
                        <span className="item__price">1650€</span>
                      </p>
                      <p className="c-text-20">
                        <FormattedMessage id="developingTeacher.sec11.course1" />
                      </p>
                    </Link>
                  )}
                </FormattedMessage>
              </div>
              <div className="col-sm-6 col-lg-4">
                <FormattedMessage id="url.teaching-kids-course">
                  {(locale) => (
                    <Link to={locale[0]} className="item">
                      <img
                        src={imgTeachYoungLearners}
                        alt="imgTeachYoungLearners"
                        className="item__img"
                      />
                      <p className="c-text-14">
                        <span className="item__price">300€ </span>
                        /<FormattedMessage id="developingTeacher.sec11.days" />
                      </p>
                      <p className="c-text-20">
                        <FormattedMessage id="developingTeacher.sec11.course2" />
                      </p>
                    </Link>
                  )}
                </FormattedMessage>
              </div>
              <div className="col-sm-6 col-lg-4">
                <FormattedMessage id="url.tefl-spanish-course-barcelona">
                  {(locale) => (
                    <Link to={locale[0]} className="item">
                      <img
                        src={imgIntensiveSpanish}
                        alt="imgIntensiveSpanish"
                        className="item__img"
                      />
                      <p className="c-text-14">
                        <span className="item__price">300€ </span>
                        /<FormattedMessage id="developingTeacher.sec11.month" />
                      </p>
                      <p className="c-text-20">
                        <FormattedMessage id="developingTeacher.sec11.course3" />
                      </p>
                    </Link>
                  )}
                </FormattedMessage>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allPrismicCourses(
      filter: {
        data: { show_on_these_pages: { elemMatch: { page: { eq: "developing-teacher-course" } } } }
      }
      sort: { order: ASC, fields: data___from }
    ) {
      edges {
        node {
          id
          data {
            name {
              text
            }
            from(formatString: "D MMMM YYYY")
            to(formatString: "D MMMM YYYY")
            price
            is_price_per_month
          }
        }
      }
    }
  }
`;

export default DevelopingTeacherCourse;
